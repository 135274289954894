import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { FaCircle } from "react-icons/fa"

import Project from "../../components/project"
import { color } from "../../utils"

const Container = styled.article`
  margin: 0;
  padding: 2rem;
  color: ${color.textBackgroundBlack};
  font-size: 1.1rem;
  line-height: 2rem;
  background-color: ${color.backgroundTransparencyBlack};
  opacity: 0;

  animation: fade 1.2s ease-in-out forwards;
  @keyframes fade {
    to {
      opacity: 1;
    }
  }

  @media screen and (min-width: 768px) {
    margin: 3% 10%;
    padding: 2rem 4rem;
    font-size: 1.2rem;
    line-height: 2.5rem;
  }
`
const Title = styled.h1`
  &.title {
    font-size: 2rem;
    text-align: center;
    margin: 2rem 0;
    color: #ffffffa8;
    font-weight: 900;
    font-family: "MuseoModerno", cursive;
  }

  @media screen and (min-width: 768px) {
    &.title {
      font-size: 2rem;
      margin: 1rem 0 3rem 0;
    }
  }
`
const SubTitle = styled.h2`
  &.subtitle {
    font-family: "MuseoModerno", cursive;
    font-size: 1.4rem;
    color: #fb3c76;
  }
`
const TextMain = styled.p`
  text-justify: auto;
  font-size: 1.1rem;
  margin-bottom: 1.7rem;

  span {
    font-size: 1.2rem;
    color: #ff9db1;
  }

  a,
  a:visited {
    color: inherit;
    text-decoration: underline;
  }

  a:hover {
    color: #fb3c76;
  }

  @media screen and (min-width: 768px) {
  }
`

const Portfolio = () => {
  return (
    <Project title="This portfolio">
      <Container>
        <Title className="title">This portfolio</Title>

        <SubTitle className="subtitle">Type of product:</SubTitle>
        <TextMain>Portfolio</TextMain>
        <SubTitle className="subtitle">Role:</SubTitle>
        <TextMain>Design and frontend development</TextMain>
        <SubTitle className="subtitle">Tech stack:</SubTitle>
        <TextMain>
          Gatsby, React, HTML, CSS, Javascript, GSAP3, GitLab, Netlify
        </TextMain>
        <SubTitle className="subtitle">Description of the project:</SubTitle>
        <TextMain>
          When I started designing and coding this website, the plan was to do
          something a bit more simple. But then, I realized it was the perfect
          time to make use of some 'skills' that I haven't been able to use that
          much in my work, as well as learn a few new ones.
        </TextMain>
        <TextMain>
          For this project, I wanted to use animation, sound, interaction,
          etc... But when we put all this into play (even if it doesn't seem
          like much is happening), performance suffers. So, I would also like to
          share some of these challenges.
        </TextMain>
        <TextMain>
          This page is a bit more boring, has a bunch of written explanations
          and no images, because I wanted to give some background to the present
          website.
        </TextMain>
        <TextMain>
          Version control was done using Git/GitLab. Deployment was made using
          GitLab and Netlify.
        </TextMain>

        <SubTitle className="subtitle">Design and Features:</SubTitle>
        <TextMain>
          - <span>Layout:</span> Each page has a fixed navigation bar and a
          fixed SVG background. The homepage has animations and interaction, and
          the rest of the pages have a block with its own layout. I used CSS
          Grid and Flexbox for this.{" "}
        </TextMain>
        <TextMain>
          - <span>Colors:</span> Many! I generally prefer more sober and
          mediterranean colors, but this time I was drawn to pinks and greens. I
          used winter sky pink (
          <FaCircle
            style={{
              color: "#FF0A78",
              fontSize: "0.7rem",
              margin: "0 4px 0 2px",
            }}
          />
          #FF0A78) for the text on the navigation bar and some titles, amaranth
          purple (
          <FaCircle
            style={{
              color: "#A00149",
              fontSize: "0.7rem",
              margin: "0 4px 0 2px",
            }}
          />
          #A00149) for regular text on toolbox and projects list, as well as
          some titles and bottle green (
          <FaCircle
            style={{
              color: "#01624C",
              fontSize: "0.7rem",
              margin: "0 4px 0 2px",
            }}
          />
          #01624C, picked from the background) for the homepage.
        </TextMain>
        <TextMain>
          {" "}
          White (
          <FaCircle
            style={{ color: "#FFF", fontSize: "0.7rem", margin: "0 4px 0 2px" }}
          />
          #222) was thoroughly used for the projects' pages, since it had more
          text, and more contrast was important. Apart from the homepage, each
          page has a semi transparent black background (
          <FaCircle
            style={{
              color: "#000000d1",
              fontSize: "0.7rem",
              margin: "0 4px 0 2px",
            }}
          />
          #000000d1) to provide good contrast for what is written in white.
        </TextMain>
        <TextMain>
          - <span>Typography:</span> Two typefaces were used: 'Quicksand' (a
          favorite of mine) and 'MuseoModerno' (with super funky{" "}
          <span style={{ fontFamily: "MuseoModerno" }}>M</span>s and{" "}
          <span style={{ fontFamily: "MuseoModerno" }}>w</span>s).{" "}
        </TextMain>
        <TextMain>
          - <span>Motion:</span> The homepage has several animations, some are
          triggered by scrolling and others are timed. I wanted the ones on the
          background to be subtle, and just create a soothing mood.{" "}
        </TextMain>
        <TextMain>
          - <span>Sound:</span> I really wanted to use sound in my portfolio. I
          think music and sounds can create a whole new experience for users. I
          ended up not using it that much... actually only once!, can you find
          it? But it's something I want to introduce in the future.{" "}
        </TextMain>
        <TextMain>
          - <span>Cursor:</span> I made a custom cursor based on code I found
          online (
          <a
            href="https://dev.to/andrewchmr/awesome-animated-cursor-with-react-hooks-5ec3"
            target="_blank"
            rel="noreferrer"
          >
            animated cursor with React Hooks
          </a>
          ). It does work well on a desktop, but there's an issue with
          touch-devices{" "}
          <span role="img" aria-label="sad face">
            {" "}
            😕{" "}
          </span>
          . I'll talk more about it on the challenges.
        </TextMain>
        <SubTitle className="subtitle">Challenges:</SubTitle>
        <TextMain>
          - <span>Performance:</span> This was the big one: how to build a
          performant and responsive website, while using animations and images?
          These are some choices I made towards a better performance:
        </TextMain>
        <TextMain>
          One thing I had to do was <span>rework some of my designs</span>. Some
          SVGs were a bit "heavy" (with noise filters, for example), which I
          found out does not go well with animations. I also optimized the SVGs
          using the site{" "}
          <a
            href="https://jakearchibald.github.io/svgomg/"
            target="_blank"
            rel="noreferrer"
          >
            SVGOMG
          </a>
          , which really helped shortening the code.{" "}
        </TextMain>
        <TextMain>
          For <span>animations</span>, I used{" "}
          <a
            href="https://greensock.com/gsap/"
            target="_blank"
            rel="noreferrer"
          >
            gsap
          </a>
          , a javascript animation library. Some of my animations are simple,
          but keep triggering rerenders, hence being heavier. I tried making css
          animations, since they can be very performant, but gsap worked much
          better. Still, this is an area that needs to be improved. It's
          actually fun for me to optimize these things, so I'm in for a treat!
        </TextMain>
        <TextMain>
          For <span>image loading optimization</span>, I made use of the plugin{" "}
          <a
            href="https://www.gatsbyjs.com/plugins/gatsby-image"
            target="_blank"
            rel="noreferrer"
          >
            'gatsby-image'
          </a>
          . Part of a responsive site is having responsive images that load
          differently and scale nicely depending on the device that's being
          used. This plugin works great in these situations, and allows for some
          laid-backeness regarding image issues. In some places I didn't use
          this plugin, since it didn't allow for the behaviour I wanted them to
          have, but these instances were regarding small images.
        </TextMain>
        <TextMain>
          I had in mind using a few more fonts, but just for a few details.
          However, it wasn't that relevant, and not having to load them saves a
          bit on network requests. For <span>font loading</span>, I made use of
          the plugin{" "}
          <a
            href="https://www.gatsbyjs.com/plugins/gatsby-plugin-webfonts/"
            target="_blank"
            rel="noreferrer"
          >
            'gatsby-plugin-webfonts'
          </a>
          , since it takes care of loading, as well as preconnect and preload
          optimizations.
        </TextMain>
        <TextMain>
          I used Chrome's Lighthouse a lot, and it often complained about{" "}
          <span>text-compression</span>, or lack thereof. For this, I used
          Brotli (
          <a
            href="https://www.gatsbyjs.com/plugins/gatsby-plugin-brotli/"
            target="_blank"
            rel="noreferrer"
          >
            'gatsby-plugin-brotli'
          </a>
          ), and it reduced file sizes drastically.{" "}
        </TextMain>
        <TextMain>
          - <span>Cross-browser compatibility:</span> Anyone working with
          HTML/CSS for longer than five minutes has run into a browser issue. I
          know I've had my fair share! In order to surpass this, the site{" "}
          <a href="https://caniuse.com" target="_blank" rel="noreferrer">
            caniuse
          </a>{" "}
          has been thoroughly used. For my cross-browser testing I used Chrome,
          Firefox and Safari.
        </TextMain>
        <TextMain>
          Regarding animations, in the beginning Chrome had way better
          performance, but as I added, removed or replaced stuff, Firefox became
          the best one. At the moment I'm rather divided about which browser is
          best... if you have a strong opinion about this, send me a{" "}
          <Link to="/contact/">message</Link>!{" "}
        </TextMain>
        <TextMain>
          {" "}
          I do love Chrome's Lighthouse! And both browser's developer tools.{" "}
          <span role="img" aria-label="face with monocle">
            {" "}
            🧐{" "}
          </span>
        </TextMain>
        <TextMain>
          - <span>Custom Cursor:</span> As I mentioned before, I decided to
          implement a custom cursor but some issues came up. On desktop it works
          well, generally being just as fast as a normal cursor (when change
          between entering and leaving a link occurs very abruptly, it staggers
          a bit).
        </TextMain>
        <TextMain>
          On touch devices, a little dot stays in the screen after touching it
          (click event). With new devices, sometimes touch/non-touch hybrids,
          it's hard to detect whether or not touch is involved. So I tried
          conditionally rendering the cursor component based on media queries
          (hover and pointer). But it didn't work, and the cursor became a
          little buggy on desktop. I also tried 'navigator.MaxTouchPoints', but
          same thing happened. I will research further for a solution, but for
          now, some cute dots will appear on mobile devices... he he
        </TextMain>
      </Container>
    </Project>
  )
}

export default Portfolio

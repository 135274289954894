import React from "react"

import Layout from "../layouts/index"
import SEO from "../components/seo"
import Background from "../components/background"

const Project = ({ title, children }) => (
  <Layout>
    <SEO title={"Project: " + title} />
    <Background opacity="low" />
    {children}
  </Layout>
)

export default Project
